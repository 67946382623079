import React from 'react'

export const GoogleCalendarAuth = () => {

    return (
        <div>
            <h1>Google Login</h1>
        </div>
    )
}
