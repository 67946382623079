export const AUTH = 'AUTH'
export const SUBMIT_HH = 'HH'
export const LOGOUT = 'LOGOUT'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_USER = 'GET_USER'
export const SUBMIT_APPOINTMENT_FORM = 'SUBMIT_APPOINTMENT_FORM'
export const RMT_AUTH = 'RMT_AUTH'
export const USER_TYPE_VERIFICATION = 'USER_TYPE_VERIFICATION'
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS'
export const GET_APPOINTMENT = 'GET_APPOINTMENT'
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT'
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT'
export const SEARCH_USERS = 'SEARCH_USERS'
export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const GET_FINANCIAL_DATA = 'GET_FINANCIAL_DATA'
export const UPDATE_USER = 'UPDATE_USER'
export const GET_USER_TREATMENTPLANS = 'GET_USER_TREATMENTPLANS'
export const CREATE_NEW_TREATMENTPLAN = 'CREATE_NEW_TREATMENTPLAN'
export const GET_TREATMENTPLAN_BYID = 'GET_TREATMENTPLAN_BYID'
export const GET_TREATMENT = 'GET_TREATMENT'
export const UPDATE_TREATMENT = 'UPDATE_TREATMENT'
export const GET_TREATMENTS_BY_CLIENTID = 'GET_TREATMENTS_BY_CLIENTID'
export const GET_TREATMENTS_BY_TREATMENTPLANID = 'GET_TREATMENTS_BY_TREATMENTPLANID'
export const ADD_TREATMENT = 'ADD_TREATMENT'
export const ADD_NEW_FINANCIAL_STATEMENT = 'ADD_NEW_FINANCIAL_STATEMENT'
export const GET_FINANCIAL_STATEMENTS = 'GET_FINANCIAL_STATEMENTS'
export const ADD_HEALTH_HISTORY = 'ADD_HEALTH_HISTORY'
export const GET_HEALTH_HISTORY = 'GET_HEALTH_HISTORY'
export const GET_ALL_TREATMENTS = 'GET_ALL_TREATMENTS'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const ADD_INCOME = 'ADD_INCOME'
export const ADD_EXPENSE = 'ADD_EXPENSE'
export const GET_INCOMES = 'GET_INCOMES'
export const GET_EXPENSES = 'GET_EXPENSES'
export const DELETE_TREATMENT = 'DELETE_TREATMENT'
export const GET_INCOME_BY_YEAR_AND_MONTH = 'GET_INCOME_BY_YEAR_AND_MONTH'
export const GET_EXPENSES_BY_YEAR_AND_MONTH = 'GET_EXPENSES_BY_YEAR_AND_MONTH'

//maintenance logs
export const GET_MAINTENANCE_LOGS = 'GET_MAINTENANCE_LOGS'

//loading screen
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN'
export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN'

//treatment plan
export const UPDATE_TREATMENT_PLAN = 'UPDATE_TREATMENT_PLAN'